.rightNavigation {
	display: flex;
	align-items: center;
	gap: 10px;
	.totalEnergyLeft {
		display: flex;
		gap: 5px;
		font-weight: 500;
		font-size: 15.5px;
		color: #606060;
		.totalWords {
			font-family: 'Poppins';
		}
	}
}
