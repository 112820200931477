.shareReports {
	.Dialog__dialogContentRoot {
		padding: 0.5rem 1.5rem !important;
		margin: initial !important;
	}
	.MuiAutocomplete-root .MuiOutlinedInput-root {
		padding: 0px !important;
		border-radius: 50px !important;
	}
	.MuiAutocomplete-inputRoot {
		padding: 0px !important;
		border-radius: 50px !important;
	}
	.bookmarkButtons {
		position: relative;
	}
	.MuiFormControlLabel-root {
		margin: 0px !important;
	}
	&__list {
		padding: 12px;
		top: 80px;
		right: -69rem;
		display: flex;
		position: fixed;
		border-radius: 22px;
		max-height: auto;
		cursor: default;
		padding: 0.5rem;
		display: flex;
		align-items: center;
		margin-inline: 10px;
		overflow: inherit;
	}
}
.shareByLink {
	.MuiFormControlLabel-root {
		margin-left: 0px !important;
	}
	.Dialog__dialogContentRoot {
		padding: 0.5rem 1.5rem !important;
		margin: initial !important;
	}
	margin: auto;
	// margin-top: 10px;
	.MuiInputBase-input {
		padding: 13px;
	}
	.input__box-container {
		width: 570px;
	}
	.input__defaultInput {
		border-radius: 0px !important ;
		// width:30rem!important;
	}

	margin-top: 20px;
}
.shareByDownload {
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	height: 100%;
	.Dialog__dialogContentRoot {
		padding: 0.5rem 1.5rem !important;
		margin: initial !important;
	}
	.downloadButton {
		height: 50px;
		border-radius: 15px;
		width: 110px;
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: center;
		padding: 10px;
	}
}

.shareByEmail {
	.input__defaultInput {
		border-radius: 0px !important ;
		// width:30rem!important;
	}
	.MuiInputBase-input {
		padding: 13px;
	}
	.chip_Container {
		max-height: 110px;
		overflow-y: scroll;
	}
	.textarea_chips {
		margin: 5px 2px;
	}
	.Dialog__dialogContentRoot {
		padding: 0.5rem 1.5rem !important;
		margin: initial !important;
	}
	.MuiAutocomplete-root .MuiOutlinedInput-root {
		padding: 5px !important;
	}
	.MuiPaper-root {
		max-width: 200px !important;
	}
	.Dialog__root {
		width: 20rem !important;
		max-width: 200px;
	}
	.shareReportroot {
		width: 20rem !important;
		max-width: 200px;
	}
	// .MuiAutocomplete-inputRoot {
	// 	padding: 0px !important;
	// 	border-radius: 50px !important;
	// }
	.MuiFormControlLabel-root {
		margin: 0px !important;
	}
	.k-pdf-export {
		background-color: red;
	}
	display: flex;
	flex-direction: column;
	gap: 10px;
	// padding: 10px 0px;
	margin-top: 20px;

	&__radio {
		display: flex;
		flex-direction: row;
		justify-content: start;
		width: 100%;
		align-items: center;
		// gap: 10px;
	}
	&__switch {
		display: flex;
		flex-direction: row;
		gap: 0px;
		margin-top: 15px;
	}
}
.scheduleReports {
	&__container {
		border: 1px solid grey;
		padding: 15px;
		border-radius: 20px;
		box-sizing: border-box;
		display: flex !important;
		flex-direction: column !important;
		gap: 20px;
	}
	.MuiInputBase-root {
		border-radius: 20px !important;
	}
}
.shareReportroot {
	min-width: 20rem !important;
	text-align: center;
	font-size: 20px !important;
}
.Dialog__dialogContentRoot {
	padding: 0.5rem 1.5rem !important;
	margin: initial !important;
}
.about_big {
	z-index: 6;
	font-family: Arial;
	font-size: 200px;
	opacity: 0.1;
	color: var(--primary-theme);
	overflow: hidden;
	padding: 0;
	margin: 0;
	bottom: 0;
	position: absolute;
	transform: rotate(-45deg);
}

.add-client-logo {
	cursor: pointer;
	position: fixed;
	right: 2rem;
	top: 4.5rem;
	z-index: 999;
	padding: 1rem;
}

.clientLogo {
	background-position: 'center';
	background-size: 'contain';
}

.fullWidthButton {
	width: inherit;
}

.submitButton {
	margin-top: 3rem !important;
}

.logoInstructions {
	font-size: 0.9rem;
	color: red;
}
