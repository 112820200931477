.ImportantMetrixTable {
    .rdt_TableHeadRow {
        padding: 0px !important;
        column-gap: 40px;
        min-height: 90px;
        border-bottom-width: 0px;

        .rdt_TableCol_Sortable {
            border: none !important
        }

        div[data-column-id="2"] {
            border: 2px solid #00ABD8;
        }

        div[data-column-id="3"] {
            border: 2px solid #9149FF;
        }

        div[data-column-id="4"] {
            border: 2px solid #E600FF;
        }

        div[data-column-id="2"],
        div[data-column-id="3"],
        div[data-column-id="4"] {
            border-bottom: none;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
        }
    }

    .rdt_TableRow {
        padding: 0px !important;
        column-gap: 40px;

        div[data-column-id="2"] {
            border: 2px solid #00ABD8;
            margin-right: 0px;
        }

        div[data-column-id="3"] {
            border: 2px solid #9149FF;
        }

        div[data-column-id="4"] {
            border: 2px solid #E600FF;
        }

        div[data-column-id="1"],
        div[data-column-id="2"],
        div[data-column-id="3"],
        div[data-column-id="4"] {
            border-bottom: none;
            border-top: none;
            padding: 0px
        }
    }

    .rdt_TableRow:last-child {
        div[data-column-id="2"] {
            border: 2px solid #00ABD8;
        }

        div[data-column-id="3"] {
            border: 2px solid #9149FF;
        }

        div[data-column-id="4"] {
            border: 2px solid #E600FF;
        }

        div[data-column-id="2"],
        div[data-column-id="3"],
        div[data-column-id="4"] {
            border-top: none;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
        }
    }
}

.HumanAITable {
    .rdt_TableHeadRow {
        padding: 0px !important;
        column-gap: 20px;
        min-height: 90px;
        border-bottom-width: 0px;

        .rdt_TableCol_Sortable {
            border: none !important
        }

        div[data-column-id="2"] {
            border: 2px solid #00ABD8;
        }

        div[data-column-id="3"] {
            border: 2px solid #9149FF;
            margin-right: 10px;
        }

        div[data-column-id="2"],
        div[data-column-id="3"],
        div[data-column-id="4"] {
            border-bottom: none;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
        }
    }

    .rdt_TableRow {
        padding: 0px !important;
        column-gap: 20px;

        div[data-column-id="2"] {
            border: 2px solid #00ABD8;
        }

        div[data-column-id="3"] {
            border: 2px solid #9149FF;
            margin-right: 10px;
        }

        div[data-column-id="1"],
        div[data-column-id="2"],
        div[data-column-id="3"],
        div[data-column-id="4"] {
            border-bottom: none;
            border-top: none;
            padding: 0px
        }
    }

    .rdt_TableRow:last-child {

        div[data-column-id="1"] {
            margin-left: 0px !important;
        }

        div[data-column-id="2"] {
            border: 2px solid #00ABD8;
        }

        div[data-column-id="3"] {
            border: 2px solid #9149FF;
        }

        div[data-column-id="2"],
        div[data-column-id="3"],
        div[data-column-id="4"] {
            border-top: none;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
        }
    }
}

.grade_Estimates_Table {
    .rdt_TableHeadRow {
        border-bottom-width: 0px;

        div {
            padding: 0px;
        }
    }

    .DatatableDark .rdt_TableCol {
        background-color: #1A1A1A;

        .header_boxes {
            background-color: black;
            height: 150px;
            width: inherit;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-content: center;
            justify-content: center;
            gap: 10px;
            margin: 1rem 0rem;
            border-radius: 20px !important;
        }
    }

    .DatatableDark .rdt_TableRow:nth-child(odd) {
        background-color: black;
    }

    .DatatableDark .rdt_TableRow:nth-child(even) {
        background-color: #1A1A1A;
    }

    .Datatable .rdt_TableCol {
        background-color: #F4F7FC;

        .header_boxes {
            background-color: white;
            height: 150px;
            width: inherit;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-content: center;
            justify-content: center;
            gap: 10px;
            margin: 1rem 0rem;
            border-radius: 20px !important;
        }
    }

    .Datatable .rdt_TableRow:nth-child(odd) {
        background-color: white;
    }

    .Datatable .rdt_TableRow:nth-child(even) {
        background-color: #F4F7FC;
    }
}