@mixin includeBasicSpacing {
	width: 95%;
	overflow-x: hidden;
	margin: auto;
	margin-bottom: 15px !important;
}

$local_transition: all 0.3s ease-in-out;

@keyframes icon_animation {
	0% {
		left: 4%;
		transform: rotate(0deg);
	}

	50% {
		left: 4%;
		transform: rotate(-20deg);
	}

	100% {
		left: 4%;
		transform: rotate(0deg);
	}
}

.data-quality-main {
	padding : 20px;
	width: 100%;

	.progress-title {
		@include includeBasicSpacing();
		display: flex;
		justify-content: space-between;

		.overall-val {
			opacity: 0.6 !important;
		}
	}

	.linearProgress-container {
		@include includeBasicSpacing();
	}

	.semi-title {
		padding-bottom: 1.2rem;
		@include includeBasicSpacing();
	}

	.Data-quality-card-container {
		//   border: 2px solid red;
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;

		.Data-quality-card {
			cursor: pointer;
			// padding: 0rem 0.5rem 1rem;
			min-height: 250px;
			// min-width: 300px;
			// max-width: 300px;
			// border: 2px solid red;
			background: white;
			border-radius: 12px;
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			justify-content: space-around;

			box-shadow: 0 25px 30px rgb(0 0 0 / 10%);
			margin-left: 20px;
			margin-bottom: 1.5rem;
			transition: $local_transition;

			.circular-progress {
				.MuiTypography-root {
					font-size: 1rem !important;
					// color: black;
				}
			}

			.circular-progress-status {
				border: 7px solid white;
				height: 80px;
				width: 80px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight: 600;
				font-size: 1rem;
				// color: white;
				box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
					rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
				// box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
			}

			.pass {
				background-color: #5fdcaa !important;
				box-shadow: 0px 0px 16px #00000029;
			}

			.fail {
				background-color: #ff003d;
				box-shadow: 0px 0px 16px #00000029;
			}

			.title_and_desc_container {
				transition: $local_transition;

				.dQheading {
					font-weight: 550;
					// color: rgb(44, 44, 44);
				}

				.hideTitle {
					display: none !important;
				}

				.showTitle {
					display: block !important;
				}

				.dQpara {
					width: 90%;
					margin: auto;
					height: fit-content;
					font-size: 1rem;
					// cursor: pointer;
					color: rgb(44, 44, 44);
				}

				.showDescription {
					// display: block;
					height: auto;
					transition: $local_transition;

					.status_icon {
						color: #181818e5;
						background-color: transparent;
						position: absolute;
						left: 4%;
						bottom: 5%;
						// border: 2px solid red;
						animation-name: icon_animation;
						animation-duration: 1s;
					}

					.success_bg {
						color: #ffffff;
						// color: #5fdcaa;
					}

					.warning_bg {
						color: #ffffff;
						// color: #ffbe76;
					}

					.error_bg {
						color: #ffffff;
						// color: #ff7979;
					}
				}

				.hideDescription {
					transition: $local_transition;
					// display: none;
					overflow: hidden;
					height: 0px;
				}
			}
		}

		.hideTopStatus {
			display: none !important;
		}

		.showTopStatus {
			display: flex !important;
		}

		.dq_card_0,
		.dq_card_1,
		.dq_card_2,
		.dq_card_3,
		.dq_card_4,
		.dq_card_5,
		.dq_card_6,
		.dq_card_7,
		.dq_card_8,
		.dq_card_9,
		.dq_card_10 {
			transition: all 0.3s ease-in-out;

			&:hover {
				background: #1dd1a18f;

				transform: scale(1.15);
			}
		}

		.success_bg {
			&:hover {
				background-color: #5fdcaa !important;
				// background-color: #ffffff;
			}
		}

		.warning_bg {
			&:hover {
				background-color: #ffbe76 !important;
				// background-color: #ffffff;
			}
		}

		.error_bg {
			&:hover {
				background-color: #ff7979 !important;
				// background-color: #ffffff;
			}
		}
	}
}
