.defaultButton {
	border-radius: 6px;
	min-width: var(--button-width);
	height: 40px;
	padding: 0.8rem 1.5rem !important;
	letter-spacing: 0px;
	cursor: pointer;
	outline: none;
	margin: 0.5rem !important;
	font-size: 1rem !important;
}

.Button__backButton {
	display: flex !important;
	color: var(--subHeading) !important;
	font-size: 16px !important;
	align-items: center;
	margin: 0.5rem;
	height: 40px;
	display: flex;
	max-width: 50px;
	padding: 0rem 0.5rem;
	cursor: pointer;
}

.Button__fontSize {
	border-radius: 25px !important;
	font-size: 1rem !important;
	text-transform: capitalize !important;
}

.Button__backButton svg {
	font-size: 16px !important;
}

.IconButton {
	background-color: var(--button-primary) !important;
}

.IconButton:hover {
	background-color: var(--button-primary);
	opacity: 0.8;
}
