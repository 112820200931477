// @import "./utils/mixins.scss";
@import "./utils/variables.scss";


body {
  margin: 0;
  // font-family: 'Poppins' !important;
  font-weight: 500 !important;
  font-size: 14px;
  // src: local('Poopins'), url('../public/font/Poppins-Regular.woff') format('woff'); /* Update the path and format accordingly */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.paragraphText {
  font-size: 17px !important;
  // font-family: 'Poppins' !important;
  font-weight: 500 !important;
  font-size: 14px;
  letter-spacing: 1px;
  // letter-spacing: 0.08px !important;
  line-height: 1.68 !important;
  font-stretch: normal !important;
  // font-weight: normal !important;
}

