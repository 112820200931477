.Datatable .rdt_Table {
	border-radius: 10px;
}

.DatatableDark .rdt_Table {
	border-radius: 0px;
	background-color: black;
}

.Datatable .rdt_TableHead {
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	overflow: auto !important;
	font-size: 15px;
}

.DatatableDark .rdt_TableHead {
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	overflow: auto !important;
	font-size: 15px;

	.rdt_TableHeadRow {
		background-color: black;
		color: white;
	}
}

.Datatable .rdt_TableHead {
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	overflow: auto !important;
	font-size: 15px;

	.rdt_TableHeadRow {
		background-color: white;
		color: black;
	}
}

.DatatableDark .rdt_TableCol {
	color: white;
	background-color: black;
}

.Datatable .rdt_TableBody,
.DatatableDark .rdt_TableBody {
	max-height: 100% !important;
	overflow: auto !important;
}

.Datatable .wuLJU,
.DatatableDark .wuLJU {
	border-radius: 20px;
}

.Datatable .rdt_Pagination {
	background-color: white;
	color: black;
}

.DatatableDark .rdt_Pagination {
	background-color: black;
	color: white;

	select option {
		background-color: black;
		color: white;
	}

	button {
		color: white;
		fill: white;
	}
}

.Datatable .rdt_TableRow {
	&:nth-child(even) {
		background-color: #faf6ff;
	}

	&:nth-child(odd) {
		background-color: white;
	}
}

.DatatableDark .rdt_TableRow {
	&:nth-child(even) {
		background-color: black;

		.rdt_TableCell {
			color: white;
		}
	}

	&:nth-child(odd) {
		background-color: #1A1A1A;
		;

		.rdt_TableCell {
			color: white;
		}
	}
}

.Datatable .rdt_TableRow {
	&:nth-child(even) {
		background-color: white;

		.rdt_TableCell {
			color: black;
		}
	}

	&:nth-child(odd) {
		background-color: #F4F7FC;

		.rdt_TableCell {
			color: black;
		}
	}
}

.Datatable ::-webkit-scrollbar,
.DatatableDark ::-webkit-scrollbar {
	width: 0.1em !important;
	height: 4px !important;
}

.Datatable ::-webkit-scrollbar-thumb,
.DatatableDark ::-webkit-scrollbar-thumb {
	background: #a45cffbd !important;
	cursor: pointer !important;
}

.Datatable ::-webkit-scrollbar-track,
.DatatableDark ::-webkit-scrollbar-track {
	background: black;
}

.Datatable,
.DatatableDark {
	&__noDataFound {
		height: 230px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 1rem;
	}
}

.MUIReactTable {
	position: relative;

	&__sorting {
		position: absolute;
		right: 0.5rem;
		top: 50%;
		transform: translate(0%, -50%);
	}

	.jump_to_page {
		width: 200px;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		position: absolute;
		bottom: 0%;
		// flex-shrink: 0;

		z-index: 999;
		transform: scale(0.9);
	}
}

.Datatable,
.DatatableDark {
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Old versions of Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
	/* Non-prefixed version, currently
			supported by Chrome, Edge, Opera and Firefox */
}

.removeBorder {
	border-bottom: 0px !important;
}

.alignSortingIcon {
	margin-top: -22px;
}