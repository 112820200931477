.Switch {
  display: flex;
  justify-content: space-between !important;
  &__icon {
    width: 22px !important;
    height: 22px !important;
    margin: 0px 6px !important;
    cursor: pointer;
    color: var(--icon-main-color) !important;
  }
  &__switchBase {
    padding: 6px !important;
  }
  &__thumb {
    width: 12px !important;
    height: 12px !important;
  }
  &__label {
    font: normal normal 600 14px/30px Lato !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__labelHover{
    color: var(--primary-theme);
  }
  &__item {
    display: flex;
    align-items: center;
  }
  &__popper{
    background-color: white;
    box-shadow: 0px 2px 2px -1px rgb(0 0 0 / 20%),
    0px 6px 8px 1px rgb(0 0 0 / 14%), 0px 2px 10px 0px rgb(0 0 0 / 12%) !important;
    border-radius: 20px;
    padding: 0.6rem 1rem;
    min-width: 9rem;
    &__section{
      margin: 0.8rem 0rem;
      display: flex;
      align-items: center;
      justify-content: space-between; 
    }
  }
}

