.accountSettings {
	padding: 15px;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	&__nameContainer {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 10px;
		&__circle {
			width: 30px;
			height: 30px;
			border-radius: 50%;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			font-size: 14px;
			text-transform: uppercase;
		}
		&__name {
			font-size: 14px;
			display: flex;
			flex-direction: row;
			gap: 10px;
			font-weight: 800;
			align-items: center;
			&__company {
				font-size: 10px;
				font-weight: 800;
			}
		}
		&__email {
			font-size: 14px;
			text-transform: initial;
		}
		.menu {
			position: absolute;
		}
	}
	&__profileCircle {
		max-width: 10px;
		height: 50px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		text-transform: uppercase;
		align-items: center;
	}
	&__imageBorder {
		border-radius: 65px;
		display: flex;
		background: linear-gradient(44.87deg, #9149ff 10.76%, #e600ff 97.56%);
		// height: 30px;
		// width: 30px;
		align-items: center;
		justify-content: center;
	}
	&__icon {
		font-size: 16px !important;
		text-transform: uppercase;
		max-width: fit-content;
		// padding: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid #bebebe;
		&__image {
			// width: 25px;
			// height: 25px;
			background: black;
			display: flex;
			border-radius: 50px;
			align-items: center;
		}
	}
	&__notifications {
		&__single {
			max-width: 100%;
			word-break: break-all;
			text-transform: uppercase;
		}
	}
	&__notificationCount {
		position: absolute;
		left: 1.5rem;
		top: -0.5rem;
		// position: absolute;
		// left: 7.3rem;
		// top: 0.5rem;
		height: 10px;
		width: 10px;
		font-size: 12px !important;
		background-color: red;
		color: white;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 10px;
		border-radius: 50%;
	}
}
