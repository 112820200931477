:root {
	--primary-theme: #9149ff;
	--sidebar-width: 232px;
	// --input--height: 18px;
	// --dropdown--height: 2.8rem;
	// --dropdown--height: 3rem;
	--header-height: 50px;

	// Icons
	--icons-color: #bfbfbf;
	--outlinedButton--text: #373a3c;
	--pageHeading: #171725;
	--subHeading: #171725;
	--sidebar-theme: white;
	--icon-main-color: #bdbdbd;
	--switch-Button: #f2e9ff;
	--sidebar-height: calc(100vh - 0px);
	--scrollbar-color: #d3d3d3;
	--button-width: 110px;
	// --secondary-theme: #1e52b9;
	--secondary-theme: #9149ff;
	--ds_side_panel_color: #9149ff;
	--ds_side_tab_active_color: white;
	--ds_panel_heading_color: white;
	--ds_header_color: white;
	--ds_graph_fill_color: white;
	--ds_graph_label_color: white;
	--rating_color: black;
	--white-color: white;
	--black-color: #000;
	--page-bg-color: #ffffff;
	--border-color: #dcdcdc;
	--input-text-color: rgba(0, 0, 0, 0.87);

	//
	--card-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.16);

	// Redesign Codes
	--button-primary: #9149ff;
	--dataTable-header: #f2e9ff;
	--page-bgColor: #f4f7fc;
	--common-textColor: #171725;
}

// @media screen and (max-width: 1366px) {
// 	:root {
// 		--sidebar-width: 224px;
// 		--input--height: 21px;
// 		--dropdown--height: 40px;
// 	}
// }
